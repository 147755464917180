// i18next-extract-mark-ns-start capabilities-combustion-ncf

import { LINKS, COMBUSTION_NAV, CAPABILITIES_PRODUCTS_COMBUSTION_NAV } from '@utils/constants';
import { graphql } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import React from 'react';

import Aside from '@components/Aside';
import AssetList from '@components/AssetList';
import Banner from '@components/Banner';
import Breadcrumbs, { Breadcrumb } from '@components/Breadcrumbs';
import Button from '@components/Button';
import PageSection from '@components/PageSection';
import Page from '@components/layout/Page';
import SEO from '@components/layout/SEO';
import ResourceListing from '@components/views/ResourceListing';
import Video from "@components/Video";
import { externalLocaleMapping } from '@utils/helpers';
import { Link, Trans, useTranslation } from 'gatsby-plugin-react-i18next';

const CapabilitiesHydrogenCombustionPage = ({
	pageContext: {
		breadcrumb: { crumbs },
	},
	data
}: {
	pageContext: {
		breadcrumb: { crumbs: Breadcrumb[] };
	};
	data: any;
}) => {
	const { t, i18n } = useTranslation();
	const hydrogen = getImage(data.hydrogen.childImageSharp.gatsbyImageData);
	const locale = externalLocaleMapping(i18n.resolvedLanguage);
	const video  = 'https://strsmktncu.blob.core.windows.net/public-assets/article-images/Solutions/Combustion/hydrogen_combustion_' + locale  + '.mp4'
	
	return (
		<Page>
			<SEO
				title={t("seo_title")}
				description={t("seo_description")}
			/>

			<Banner
				title={t("title")}
				image={{
					src: data.banner.childImageSharp.gatsbyImageData,
					alt: '',
				}}
				size="standard"
			/>

			<Breadcrumbs crumbs={crumbs} />

			<PageSection
				className="section__content section__content--dual"
				layout="none">
				<div className="col xl7 m12 m-spacer page__content page__content--dual">
				<Trans i18nKey="content_title">
						<h2>Simulating ammonia and hydrogen combustion</h2>
						</Trans>
						<Video
						   videoSrcURL={video}
						   videoTitle="Hydrogen combustion"
					    />
						<Trans i18nKey="content_1">
						<p>
							Non-carbon fuel combustion represents a promising avenue for decarbonizing internal combustion engine (ICE)
							applications across various sectors, including heavy-duty transportation (both on and off-road), rail systems,
							power generation, and marine sectors.
							Notably, hydrogen and ammonia are being actively explored as emerging green fuel options.
						</p>
						<p>
							Hydrogen ICEs, for instance, offer the potential for high power density and remarkably low tailpipe emissions.
							However, they come with unique challenges related to designing systems for a gaseous fuel,
							which possesses distinct mixing, burn rate, and combustion control requirements.
							The primary pollutant of concern with Hydrogen ICEs is NOx, a challenge mitigated by operating the engine with
							lean equivalence ratios and employing Exhaust Gas Recirculation (EGR).
							Under these conditions, hydrogen combustion displays thermo-diffusive instability,
							making the application of conventional flame propagation and heat transfer models challenging.
						</p>
						<p>
							In contrast, ammonia combustion is generally more stable, but initiation of combustion and sustaining rapid flame
							propagation become a challenge under typical operating conditions, prompting the development of innovative
							piloting strategies and multi-fuel applications.
						</p>
						<p>
							The Realis Simulation toolchain incorporates specialised models tailored to address hydrogen and
							multi-fuel combustion across the entire spectrum of engine operating conditions.
							This empowers our customers to bring new non-carbon ICE products to the market,
							paving the way for cleaner and more sustainable transportation and power generation solutions.
						</p>
					</Trans>

					{hydrogen && (
						<p>
							<GatsbyImage image={hydrogen} alt="VECTIS H2 DI engine" />
							<Trans i18nKey="small"><small>Injection and flame propagation in an H2 DI engine</small>
							</Trans>
						</p>
					)}
				</div>
				<Aside>
					<Aside.Widget
						title={t("_CleanCombustion")}
						className="widget__sibling__pages">
						<AssetList>
							{COMBUSTION_NAV.map((item, i) => (
								<Link
									key={i}
									to={item.value}
									className="assets__link"
									activeClassName="active">
									{t(item.labelkey) /* i18next-extract-disable-line */}
								</Link>
							))}
						</AssetList>
					</Aside.Widget>
					<Aside.Widget
						title={t("_Products")}
						className="widget__sibling__pages">
						<AssetList>
							{CAPABILITIES_PRODUCTS_COMBUSTION_NAV.map((item, i) => (
								<Link
									key={i}
									to={item.value}
									className="assets__link"
									activeClassName="active">
									{t(item.labelkey) /* i18next-extract-disable-line */}
								</Link>
							))}
						</AssetList>
					</Aside.Widget>
					<Link
						to={LINKS.ABOUT_CONTACT}
						className="button--more">
						{t("_GetInTouch")}
					</Link>
				</Aside>
			</PageSection>

			<ResourceListing
				resources={data.resources}
				title={t("_OurInsights")}
				layout="fullwidth"
				actions={
					<div className="text--center">
						<Button to={LINKS.INSIGHTS} type="more">
							{t("_SeeAllInsights")}
						</Button>
					</div>
				}
			/>
		</Page>
	);
};

export default CapabilitiesHydrogenCombustionPage;

export const pageQuery = graphql`
query ($language: String!) {
	locales: allLocale(
		filter: { ns: { in: ["capabilities-combustion-ncf", "_common"] }, language: { eq: $language } }
	) {
		edges {
			node {
				ns
				data
				language
			}
		}
	}
	banner: file(relativePath: { eq: "content/capabilities/combustion/non-carbon/Realis-Simulation_1440_combustionHydrogen_cropped.jpg" }) {
		...imageBreaker
	}
	hydrogen: file(relativePath: { eq: "content/products/vectis/Realis-Simulation_VECTIS_H2_DI.png" }) {
		...imageBreaker
	}
	resources: allMarkdownRemark(
		filter: {
			fileAbsolutePath: { regex: "/resources/" }
			fields: { language: { eq: $language } }
			frontmatter: { tags: { in: "Hydrogen" } }
		}
		sort: { fields: frontmatter___date, order: DESC }
		limit: 9
	) {
		nodes {
			fields {
				slug
			}
			frontmatter {
				title
				summary
				image {
					...imageStandard
				}
				type
			}
		}
	}
}
`;
